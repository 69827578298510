#qsLoginBtn,#qsSignupBtn {
  background-color: #FFF;
  border: #FFF;
  color: #099a1e;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  min-width: 80px !important;
}

#slackAppBtn {
  background-color: #FFF;
  border: #FFF;
  color: #099a1e;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  min-width: 80px !important;
  margin-right: 9px;
}

#slackAppBtn-2 {
  background-color: #00000000;
  border: 1px solid #FFF;
  color: #FFF;
  min-width: 135px !important;
  font-size: 1rem;
  padding: 0.15rem 0.1rem 0.15rem 0.1rem;
  margin-left: 1rem;
}

/* 画面幅が 768px 以下の場合 */
@media (max-width: 768px) {
  #slackAppBtn-2 {
    border: none;
    margin-left: 0.3rem;
	}
}

#qsLoginBtn:hover,#qsLoginBtn:focus,#qsSignupBtn:hover,#qsSignupBtn:focus,#slackAppBtn:hover,#slackAppBtn:focus {
  background-color: #DDD;
  border: #DDD;
  min-width: 80px !important;
}

#slackAppBtn-2:hover,#slackAppBtn-2:focus {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #FFF;
  min-width: 135px !important;
}

.navbar {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  color: white !important;
  background: -webkit-linear-gradient(70deg, rgb(16, 169, 28), rgb(159, 224, 0));
  background: linear-gradient(70deg, rgb(16, 169, 28), rgb(159, 224, 0));
}
.navbar-nav:last-child {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  background: rgb(241 255 200 / 36%);
}

.nav-user-profile {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.nav-item>a {
  color: white !important;
  white-space: nowrap;
}

.brand-name {
  color: white;
  font-size: 1.4rem;
}

.nav-link {
  color: white !important;
  font-size: 1.2rem;
}
.navbar-nav {
  padding: 0 0 0.2rem 0;
}
.router-link-exact-active {
  border-bottom: 1px solid white !important;
}
.navbar-collapse>ul>li>.router-link-exact-active {
  border-left: none !important;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: #b4b1b1 !important;
}
.dropdown-item:focus{
  outline-color: #b4b1b1 !important
}

.app-logo {
  max-width: 280px !important;
}
