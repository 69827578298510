.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

#register-button, #register-button-sd, #register-button-hd {
  background: -webkit-linear-gradient(70deg, rgb(255, 167, 35), rgb(254, 121, 68));
  background: linear-gradient(70deg, rgb(255, 167, 35), rgb(254, 121, 68));
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  font-weight: 600;
  transition: .3s;
  overflow: hidden;
}
#register-button:hover, #register-button-sd:hover, #register-button-hd:hover {
  background: -webkit-linear-gradient(70deg, rgb(255, 153, 0), rgb(255, 73, 0));
  background: linear-gradient(70deg, rgb(255, 153, 0), rgb(255, 73, 0));
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.6);
}

h1, h2 {
  font-weight: 700 !important;
}