#save-button {
  background: -webkit-linear-gradient(70deg, rgb(251, 163, 31), rgb(251, 94, 31));
  background: linear-gradient(70deg, rgb(251, 163, 31), rgb(251, 94, 31));
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  font-weight: 600;
  color: white;
}
#save-button:hover {
  background: -webkit-linear-gradient(70deg, rgba(251, 163, 31, .9), rgba(251, 94, 31, .9));
  background: linear-gradient(70deg, rgba(251, 163, 31, .9), rgba(251, 94, 31, .9));
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
}

#test-mail-button {
  background: rgb(180, 177, 177);
  border: none;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  font-weight: 400;
  color: white;
}
#test-mail-button:hover {
  background: rgb(129, 127, 127);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.recommend-setting-form {
  max-width: 800px;
}