body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", 游ゴシック体,
  YuGothic, 游ゴシック, "Yu Gothic", "ヒラギノ角ゴ ProN W3",
  "Hiragino Kaku Gothic ProN", Arial, メイリオ, Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inner-fit{
  max-width: 100% !important;
  height: auto;
}

.triangle2bottom{
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid black;
}

.card {
  /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5); */
}

.card-body {
  padding: 0.5rem;
}

.btn {
  border-radius: .3rem;
}