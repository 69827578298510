.quickstart_steps{
  max-width: 800px;
}

.quickstart_steps>h1{
  font-size: 1.6rem;
}

.quick-start-container{
  background-color: #f9f6f099;
}