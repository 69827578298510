footer {
  background: -webkit-linear-gradient(-70deg, rgb(16, 169, 28), rgb(159, 224, 0));
  background: linear-gradient(-70deg, rgb(16, 169, 28), rgb(159, 224, 0));
  color: white !important;
  padding: 0;
  min-height: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
footer>.container {
  padding: 0;
  max-width: none;
}
footer>.container>h1 {
  font-size: 1.6rem;
  color: white !important;
}
.footer-links>span>a {
  color: white !important;
}
.footer-links>span {
  display: inline-block;
}