.top-overlook{
  background-color: #6a6a6a;
  color: white;
  font-size: 2.5rem;
}

#contact-button {
  background-color: white;
  color: black;
  font-weight: 700;
}

.solution-digest-issue{
  max-width: 800px;
}

.solution-digest-issue>.solution {
  max-width: 500px;
}

.solution-digest-container{
  background-color: #f9f6f099;
}