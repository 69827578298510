.service-entry-banner{
  max-width: 800px;
}

h5 {
  font-size: 1.8em;
  font-weight: 700;
  line-height: 1.5;
}

.detail-overlook{
  background-color: #6a6a6a;
  color: white;
}

.detail-caption {
  font-size: 1.1rem;
}

.detail-card {
  border: none;
}
