.hojokin-table>h1 {
  font-size: 2rem;
}

.hojokin-table{
  max-width: 800px;
}

.hojokin_abstract,.hojokin_budgetMinistry,.hojokin_termContinue,.hojokin_termBudget {
  font-size: 0.85rem;
}

.table th, .table td {
  padding: 0.34rem;
}